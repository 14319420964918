document.addEventListener('rsq.service.sublimeskinz.ready', (e) => {
  //
  const sublimeSkinzLpPageId = ibiza_theme_vars.sublimeSkinzLpPageId
  const bodyEl = document.querySelector(`body.page-id-${sublimeSkinzLpPageId}`)

  if (bodyEl) {
    console.log('Sublime Skinz Px send')

    // trackTransaction with Sublime Skinz Pixel
    // <!-- Conversion Pixel - QA_FR_PiscineIbiza_2022 - DO NOT MODIFY -->
    const img_1572112 = new Image(1, 1)
    img_1572112.src = 'https://secure.adnxs.com/px?id=1572112&t=2'

    // <!-- Conversion Pixel - Piscine Ibiza_Visites LP Sublime - DO NOT MODIFY -->
    const img_1572131 = new Image(1, 1)
    img_1572131.src = 'https://secure.adnxs.com/px?id=1572131&t=2'
  }

  /* Px on send form
  document.addEventListener('wpcf7mailsent', (event) => {
    const formEl = document.querySelector(`${event.detail.apiResponse.into} form`)
    const formElName = formEl.getAttribute('name')
    if (formElName == 'configurateur') {
      // trackTransaction with Sublime Skinz Pixel
      // <!-- Conversion Pixel - QA_FR_PiscineIbiza_2022 - DO NOT MODIFY -->
      const img_1572112 = new Image(1, 1)
      img_1572112.src = 'https://secure.adnxs.com/px?id=1572112&t=2'

      // <!-- Conversion Pixel - Piscine Ibiza_Visites LP Sublime - DO NOT MODIFY -->
      const img_1572131 = new Image(1, 1)
      img_1572131.src = 'https://secure.adnxs.com/px?id=1572131&t=2'
    }
  })
  */
})
