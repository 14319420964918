import enquire from 'enquire.js';

(function ($, w, d, undefined) {
  // Home - Partner
  $('.tips-carousel-wrapper').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    arrows: false,
    infinite: true,
    lazyLoad: 'progressive',
    nextArrow: '<span class="slick-next"><i class="far fa-chevron-right"></i></span>',
    prevArrow: '<span class="slick-prev"><i class="far fa-chevron-left"></i></span>',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          speed: 100,
          dots: true,
          centerPadding: '20px',
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  })

  $('.dealer-carousel-wrapper').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    speed: 100,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    mobileFirst: true,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 480,
        settings: {
          swipeToSlide: false,
          speed: 300
        }
      }
    ]
  })

  $('.pool-options .options-list').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    speed: 100,
    dots: true,
    arrows: false,
    infinite: true,
    centerMode: true,
    centerPadding: '25%',
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll:  1,
          dots: false,
          arrows: true,
          centerMode: false
        }
      }
    ]
  })

  enquire.register('screen and (max-width:991px)', {
    match: () => {
      $('.related-pool-list, .dealer-spas-wrapper').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 100,
        dots: false,
        arrows: false,
        infinite: true,
        centerMode: true,
        centerPadding: '25%',
        lazyLoad: 'progressive',
        responsive: [
          {
            breakpoint: 480,
            settings: {
              centerPadding: '5%'
            }
          }
        ]
      })
    },
    unmatch: () => {
      $('.related-pool-list').slick('unslick')
      $('.dealer-spas-wrapper').slick('unslick')
      // $('.pool-options .options-list').slick('unslick')
    }
  })
}($, window, document))
