import counterUp from 'counterup2'

document.addEventListener('aos:in:xp-block', ({
  detail
}) => {
  const element = detail.querySelector('.number')
  counterUp(element, {
    duration: 1000,
    delay: 16
  })
})

document.addEventListener('aos:in:pool-block', ({
  detail
}) => {
  const element = detail.querySelector('.number')
  counterUp(element, {
    duration: 1000,
    delay: 16
  })
})

document.addEventListener('aos:in:dealer-block', ({
  detail
}) => {
  const element = detail.querySelector('.number')
  counterUp(element, {
    duration: 1000,
    delay: 16
  })
})
