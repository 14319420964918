/**
 * Gestion des liens externes
 */

const $ = require('jquery')

// liens externe dans nouvel onglet
$(document).ready(() => {
  const links = document.links
  const localhost = window.location.hostname
  for (let i = 0; i < links.length; i++) {
    const extension = links[i].href.substr(-3).toLowerCase()
    const isExternalized = $(links[i]).hasClass('externalized')

    if (links[i].hostname !== localhost && (links[i].protocol === 'http:' || links[i].protocol === 'https:') || extension === 'pdf' || isExternalized) {
      $(links[i]).click(function (event) {
        event.preventDefault()

        if (!$(this).hasClass('no_click')) {
          if ($(this).hasClass('no_external')) {
            window.location.href = $(this).attr('href')
          } else {
            window.open($(this).attr('href'))
          }
        }
      })
    }
  }
})
