document.addEventListener('DOMContentLoaded', () => {
  const spaForms = document.querySelectorAll('.spa-contact form')
  const closeButton = document.querySelectorAll('.spa-contact .close')
  const technicalSheetLink = document.querySelector('#spa_technical_sheet')
  const spaName = document.querySelectorAll('.highlight')

  if (spaForms) {
    spaForms.forEach((form) => {
      form.addEventListener('submit', (event) => {
        event.preventDefault()

        const requiredFields = form.querySelectorAll(
          '.wpcf7-validates-as-required[aria-required="true"]'
        )
        let allFieldsValid = true
        requiredFields.forEach((field) => {
          if (!field.value.trim()) {
            allFieldsValid = false
            field.classList.add('is-invalid')
          } else {
            field.classList.remove('is-invalid')
          }
        })

        const consentField = form.querySelector(
          'input[name="requester_consent"]'
        )

        if (!allFieldsValid) {
          form.querySelector('.spa-form-message').textContent =
                        'Veuillez remplir tous les champs obligatoires.'
          form.querySelector('.spa-form-message').classList.add(
            'text-danger'
          )
          return
        }

        if (form.name === 'demande-de-fiche-technique') {
          const technicalSheet = technicalSheetLink.href
          window.open(technicalSheet, '_blank')

          if (consentField && !consentField.checked) {
            setTimeout(() => {
              form.reset()
              closeButton.forEach((button) => {
                button.click()
              })
            }, 1000)
            return
          }
        }

        const formData = new FormData(form)

        const wpcf7Inputs = form.querySelectorAll(
          'input[name^="_wpcf7"]'
        )
        wpcf7Inputs.forEach((input) => {
          formData.append(input.name, input.value)
        })

        formData.append('action', 'wpcf7_ajax_submit')

        const formAction =
                    form.action ||
                    `${document.location.origin}/wp/wp-admin/admin-ajax.php`

        fetch(formAction, {
          method: 'POST',
          body: formData
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `HTTP error! Status: ${response.status}`
              )
            }

            if (response.status === 200) {
              form.querySelector(
                '.spa-form-message'
              ).textContent =
                                'Votre message a été envoyé avec succès !'
              form.querySelector(
                '.spa-form-message'
              ).classList.add('text-success')
              form.querySelector(
                '.spa-form-message'
              ).classList.remove('text-danger')
            } else {
              form.querySelector(
                '.spa-form-message'
              ).textContent =
                                'Une erreur est survenue lors de la soumission du formulaire !'
              form.querySelector(
                '.spa-form-message'
              ).classList.add('text-danger')
            }

            if (typeof window._paq !== 'undefined') {
              console.log('paq')
              window._paq.push([
                'trackEvent',
                'Formulaires Spas',
                form.name,
                spaName[0]?.textContent || '',
                1
              ])
            }

            if (typeof fbq !== 'undefined') {
              console.log('fbq')
              fbq('trackCustom', 'Lead', {
                content_type: form.name,
                spa_name: spaName[0]?.textContent || ''
              })
            }

            if (typeof gtag !== 'undefined') {
              console.log('gtag')
              gtag('event', 'form_submission', {
                event_category: 'Formulaires Spas',
                event_label: form.name,
                value: spaName[0]?.textContent || ''
              })
            }

            setTimeout(() => {
              form.reset()
              closeButton.forEach((button) => {
                button.click()
              })

              form.querySelector(
                '.spa-form-message'
              ).classList.remove('text-success', 'text-danger')
              form.querySelector(
                '.spa-form-message'
              ).textContent = ''

              if (form.name === 'demande-de-fiche-technique') {
                const technicalSheet = technicalSheetLink.href
                window.open(technicalSheet, '_blank')
              }
            }, 1000)
          })
          .catch((error) => {
            form.querySelector('.spa-form-message').textContent =
                            'Une erreur est survenue lors de la soumission du formulaire !'
            form.querySelector('.spa-form-message').classList.add(
              'text-danger'
            )
          })
      })
    })
  }
})
