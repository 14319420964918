// const parseUrl = require('locutus/php/url/parse_url')
// const queryString = require('query-string')

document.addEventListener('rsq.service.criteo.onetag.ready', (e) => {
  const deviceType = /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd'
  // let urlParts = parseUrl(window.location.href)
  // Account Ibiza : 55426
  const accountId = e.detail.criteoAccountId

  window.criteo_q = window.criteo_q || []

  /**
     * Events :
     * - viewHome
     * - viewList
     * - viewItem
     * - viewBasket
     * - trackTransaction
     */
  if ($('.pool-products-list').length > 0) {
    // viewList
    const product_ids = []
    $('.pool-product-item > article').slice(0, 3).each(function (index) {
      product_ids.push($(this).attr('data-old-node-id'))
    })

    if (product_ids.length > 0) {
      window.criteo_q.push(
        {
          event: 'setAccount',
          account: accountId
        },
        {
          event: 'setSiteType',
          type: deviceType
        },
        {
          event: 'viewList',
          item: product_ids
        }
      )
    }
  } else if ($('body').hasClass('single-product')) {
    // viewItem
    window.criteo_q.push(
      {
        event: 'setAccount',
        account: accountId
      },
      {
        event: 'setSiteType',
        type: deviceType
      },
      {
        event: 'viewItem',
        item: $('article.single-product-details').attr('data-old-node-id')
      }
    )
  } else if ($('form[name=configurateur]').length > 0) {
    // viewBasket
    window.criteo_q.push(
      {
        event: 'setAccount',
        account: accountId
      },
      {
        event: 'setSiteType',
        type: deviceType
      },
      {
        event: 'viewBasket',
        item: [{
          id: 'fake_product_id',
          price: 1,
          quantity: 1
        }]
      }
    )
  } else {
    // viewHome
    window.criteo_q.push(
      {
        event: 'setAccount',
        account: accountId
      },
      {
        event: 'setSiteType',
        type: deviceType
      },
      {
        event: 'viewHome'
      }
    )
  }

  //
  document.addEventListener('wpcf7mailsent', (event) => {
    const formEl = document.querySelector(`${event.detail.apiResponse.into} form`)
    const formElName = formEl.getAttribute('name')
    if (formElName == 'configurateur') {
      // trackTransaction
      window.criteo_q.push(
        {
          event: 'setAccount',
          account: accountId
        },
        {
          event: 'setSiteType',
          type: deviceType
        },
        {
          event: 'trackTransaction',
          id: Math.random().toString(36).substr(2, 10),
          item: [{
            id: 'fake_product_id',
            price: 1,
            quantity: 1
          }]
        }
      )
    }
  })

  console.info('READY : Criteo END')
})
