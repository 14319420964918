import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic'
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import {
  TweenMax
} from 'gsap/TweenMax'

document.addEventListener('DOMContentLoaded', () => {
// init controller
  let controller = new ScrollMagic.Controller()
  // Handle Enable/Disable ScollMagic depending on the devices
  const deskQM = window.matchMedia('(min-width:1366px)')
  let tweenList
  if (deskQM.matches) {
    window.tweenList = initAnimation(controller, deskQM)
  }
  deskQM.addListener((query) => {
    if (query.matches) {
      controller = new ScrollMagic.Controller()
      setTimeout(() => {
        window.tweenList = initAnimation(controller, query)
      }, 700)
    } else {
      if (typeof window.tweenList !== 'undefined') {
        window.tweenList.forEach((tween) => {
          tween.clear()
          tween.kill()
        })
      }
      if (controller) {
        controller.destroy(true)
      }
      controller = null
      cleanWrapperStyle()
    }
  })
})

function initAnimation(controller, mediaQuery) {
  const header = document.querySelector('#header')
  // build scene
  const blockBuildTech = document.querySelector('.home .block-build-tech')
  const tweenList = []
  if (blockBuildTech) {
    const timeline = new TimelineMax()
      .add(TweenMax.to('.animation-wrapper-left', 1.5, {
        x: 0,
        opacity: 1
      }), 'first')
      .add(TweenMax.to('.animation-wrapper-right', 1.5, {
        x: 0,
        opacity: 1
      }), 'first')
      .add(TweenMax.from('.pool-link-item:not(.pin-up)', 1, {
        y: -100,
        opacity: 0
      }), '2')
      .add(TweenMax.from('.pool-link-item.pin-up', 1, {
        y: 100,
        opacity: 0
      }), '2')


    const scene = new ScrollMagic.Scene({
      triggerElement: '.block-build-tech',
      triggerHook: 0.6,
      duration: '50%',
      offset: 0
    })
      .setTween(timeline) // trigger a TweenMax.to tween
      // .setPin('.block-build-tech', {pushFollowers : true})
      .addTo(controller)


    if (ibiza_theme_vars.env == 'development') {
      // scene.addIndicators({name: "Build Tech ! (duration: 1000)"}) // add indicators (requires plugin)
    }
    tweenList.push(timeline)
  }

  /* const blockPoolFeatures = document.querySelector('.pool-features')
  if (blockPoolFeatures) {
    const timeline = new TimelineMax()
      .add(TweenMax.from('.features-anim-wrapper-left', 1, {
        x: '-105%',
        opacity: 1
      }), 'first')
      .add(TweenMax.from('.features-anim-wrapper-right', 1, {
        x: '105%',
        opacity: 1
      }), 'first')*/
  /* let timeline = new TimelineMax()
      .add(TweenMax.from(".features-anim-wrapper-left", 0.5,  {y:'-100%', opacity:0}), "first")
      .add(TweenMax.from(".features-anim-wrapper-right", 0.5,  {y:'100%', opacity:0}), "first")
      .add(TweenMax.to(".features-anim-wrapper-left", 0.5,  {css:{y: '100%', opacity:0},delay:0.75}), "second")
      .add(TweenMax.to(".features-anim-wrapper-right", 0.5,  {css:{y: '-100%', opacity:0},delay:0.75}), "second")

    const scene = new ScrollMagic.Scene({
      triggerElement: '.pool-features',
      triggerHook: 0.5,
      duration: '50%',
      offset: 0
    })
      .setTween(timeline) // trigger a TweenMax.to tween
      // .setPin('.pool-features')
      .addTo(controller)

    if (ibiza_theme_vars.env == 'development') {
      // scene.addIndicators({name: "Pool Features ! (duration: 2000)"}) // add indicators (requires plugin)
    }
    tweenList.push(timeline)
  }
*/
  const blockPoolGarantees = document.querySelector('.pool-garantees')
  if (blockPoolGarantees) {
    const timeline = new TimelineMax()
      .add(TweenMax.from('.garantees-anim-wrapper-left', 1, {
        x: '-105%',
        opacity: 1
      }), 'first')
      .add(TweenMax.from('.garantees-anim-wrapper-right', 1, {
        x: '105%',
        opacity: 1
      }), 'first')
    /* let timeline = new TimelineMax()
      .add(TweenMax.from(".garantees-anim-wrapper-left", 1,  {y:'-100%', opacity:0}), "first")
      .add(TweenMax.from(".garantees-anim-wrapper-right", 1,  {y:'100%', opacity:0}), "first")
      .add(TweenMax.to(".garantees-anim-wrapper-left", 1,  {css:{y: '100%', opacity:0},delay:0.45}), "second")
      .add(TweenMax.to(".garantees-anim-wrapper-right", 1,  {css:{y: '-100%', opacity:0},delay:0.45}), "second")*/

    const scene = new ScrollMagic.Scene({
      triggerElement: '.pool-garantees',
      triggerHook: 0.5,
      duration: '50%',
      offset: 0
    })
      .setTween(timeline) // trigger a TweenMax.to tween
      // .setPin('.pool-garantees', {pushFollowers : true})
      .addTo(controller)


    if (ibiza_theme_vars.env == 'development') {
      // scene.addIndicators({name: "Pool Garantees ! (duration: 2000)"}) // add indicators (requires plugin)
    }
    tweenList.push(timeline)
  }
  return tweenList
}

function cleanWrapperStyle() {
  const blockBuildTech = document.querySelector('.home .block-build-tech')
  const blockPoolGarantees = document.querySelector('.pool-garantees')
  // const blockPoolFeatures = document.querySelector('.pool-features')

  if (blockPoolGarantees) {
    const wrapperLeft = document.querySelector('.garantees-anim-wrapper-left')
    const wrapperRight = document.querySelector('.garantees-anim-wrapper-right')
    wrapperLeft.style.transform = ''
    wrapperLeft.style.opacity = ''
    wrapperRight.style.transform = ''
    wrapperRight.style.opacity = ''
  }
  /* if (blockPoolFeatures) {
    const wrapperLeft = document.querySelector('.features-anim-wrapper-left')
    const wrapperRight = document.querySelector('.features-anim-wrapper-right')
    wrapperLeft.style.transform = ''
    wrapperLeft.style.opacity = ''
    wrapperRight.style.transform = ''
    wrapperRight.style.opacity = ''
  }*/
  if (blockBuildTech) {
    const wrapperLeft = document.querySelector('.animation-wrapper-left')
    const wrapperRight = document.querySelector('.animation-wrapper-right')
    const pin = document.querySelectorAll('.pool-link-item')
    wrapperLeft.style.transform = ''
    wrapperLeft.style.opacity = ''
    wrapperRight.style.transform = ''
    wrapperRight.style.opacity = ''
    pin.forEach((pinEl) => {
      pinEl.style.transform = ''
      pinEl.style.opacity = ''
    })
  }
}
