import AOS from 'aos'

AOS.init()

$('a[href*=\'#\']:not([href=\'#\']):not([data-toggle=\'collapse\'])').click(function (e) {
  if (
    location.hostname == this.hostname &&
    this.pathname.replace(/^\//, '') == location.pathname.replace(/^\//, '')
  ) {
    e.preventDefault()
    let anchor = $(this.hash)
    anchor = anchor.length ? anchor : $(`[name=${this.hash.slice(1)}]`)
    if (anchor.length) {
      $('html, body').animate({
        scrollTop: anchor.offset().top - 100
      }, 500)
    }
  }
})
