(function ($, w, d, undefined) {
  d.addEventListener('DOMContentLoaded', () => {
    const loader = d.querySelector('.loader')
    const body = d.querySelector('body')
    body.classList.remove('loading')
    loader.classList.add('hidding')
    loader.addEventListener('transitionend', () => {
      // Create Element.remove() function if not exist
      if (!('remove' in Element.prototype)) {
        Element.prototype.remove = function () {
          if (this.parentNode) {
            this.parentNode.removeChild(this)
          }
        }
      }
      loader.remove()
    })
  })
}($, window, document))
