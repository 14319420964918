document.addEventListener('DOMContentLoaded', () => {
  $('a[href="#ytvideo"]').click(function (event) {
    event.preventDefault()
    $('#modal-video').modal()

    document.modal_player = new YT.Player('ytvideo-modal', {
      videoId: $(this).data('vid'), // YouTube Video ID
      width: 1024, // Player width (in px)
      height: 590, // Player height (in px)
      playerVars: {
        autoplay: 1, // Auto-play the video on load
        controls: 1, // Show pause/play buttons in player
        showinfo: 0, // Hide the video title
        modestbranding: 1, // Hide the Youtube Logo
        loop: 1, // Run the video in a loop
        fs: 1, // Hide the full screen button
        cc_load_policty: 0, // Hide closed captions
        iv_load_policy: 3, // Hide the Video Annotations
        autohide: 0, // Hide video controls when playing
        playlist: $(this).data('vid')
      },
      events: {
        onReady(e) {
          e.target.mute()
          e.target.playVideo()
        }
      }
    })
  })

  $('#modal-video').on('hide.bs.modal', () => {
    if (document.modal_player != undefined) {
      document.modal_player.destroy()
    }
  })

  if ($(window.location.hash).length && $(window.location.hash).hasClass('modal')) {
    $(window.location.hash).modal()
  }
})
