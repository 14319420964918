(function ($, window, document, undefined) {
  document.addEventListener('rsq.service.sib.ready', () => {
    const wpcf7Object = window.wpcf7 || window._wpcf7
    if (typeof wpcf7Object !== 'undefined') {
      document.addEventListener('wpcf7mailsent', (event) => {
        if (typeof window.sendinblue === 'undefined') {
          return false
        }

        const data = {}
        let allow_marketing = false
        const formEl = document.querySelector(
          `${event.detail.apiResponse.into} form`
        )
        const formElName = formEl.getAttribute('name')
        switch (formElName) {
          case 'configurateur':
            data.FORM_NAME = 'piscine_devis'
            data.QUOTE_AUTOMATION = true
            allow_marketing = true
            break
          case 'contact':
            data.FORM_NAME = 'piscine_contact'
            break
          case 'demande-de-catalogue':
            data.FORM_NAME = 'piscine_catalogue'
            break
          case 'faq':
            data.FORM_NAME = 'piscine_faq'
            break
          default:
        }

        event.detail.inputs.forEach((input) => {
          switch (input.name) {
            case 'requester_firstname':
              data.PRENOM = input.value
              break
            case 'requester_name':
              data.NOM = input.value
              break
            case 'requester_phone':
              data.PHONE = input.value
              break
            case 'requester_email':
              data.EMAIL = input.value
              break
            case 'requester_zipcode':
              data.CODE_POSTAL = input.value
              break
            case 'requester_city':
              data.VILLE = input.value
              break
            /* case 'requester_message':
              data.message = input.value
              break*/
            case 'catalog[]':
              switch (input.value) {
                case 'Catalogue Modèles Piscines':
                  data.CATALOG_MODEL = true
                  break
                case 'Catalogue Produits et Accessoires Piscine':
                  data.CATALOG_ACCESSORIES = true
                  break
                case 'Dépliant comparatif Formes de Bassins':
                  data.CATALOG_SHAPE = true
                  break
                case 'Catalogue Spas':
                  data.CATALOG_SPA = true
                  break
                default:
              }
              break
            case 'check_marketing':
              allow_marketing = true
              break
            default:
              break
          }
        })

        if (allow_marketing) {
          window.sendinblue.identify(data.EMAIL, data)
        }
      })
    }
  })
}(jQuery, window, document))
