jQuery(document).ready(($) => {
  var forms2GA = {
    // Documentation : https://docs.google.com/document/d/1ylRSpmQjPAQU1i15enmBYO8YRMU6Ee0M0wyJRoU6qg0/
    run: false,
    gaKey: '',
    langCode: '',
    debug: 'Forms-analytics Events v.1.2.2\n',
    gaType: '',

    getLangCode() {
      // Prend la langue de la page, ou celle d'une div définie (id='langCode') contenant ICL_LANGUAGE_CODE si elle existe
      forms2GA.langCode = document.getElementsByTagName('html')[0].lang.substring(0, 2).toUpperCase()
      if (document.getElementById('langCode')) {
        forms2GA.langCode = document.getElementById('langCode').innerHTML.toUpperCase()
      }
      return forms2GA.langCode
    },

    triggerGA() {
      // tout envoyer sauf 'send' et 'event'
      // ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
      // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
      const gaParams = ['send', 'event']
      const args = Array.prototype.slice.call(arguments)
      gaParams.push.apply(gaParams, args)

      switch (forms2GA.gaType) {
        case 'universal':
          window.ga.apply(this, gaParams)
          break
        case 'analytics':
          // Specific for revea because _gaq is implemented by GTM
          window._gaq.push(['gtm1._trackEvent', args[0], args[1]])
          break
        case 'gtag':
          window.gtag('event', args[1], {
            event_category: args[0],
            event_label: args[2]
          }
          )
          break
      }
      forms2GA.debug += `GA triggered with those values: ${gaParams.join(', ')}\n`
    },

    setAllOtherListeners() {
      $(document).ajaxSuccess((event, xhr, settings) => {
        if (typeof settings.data === 'string') {
          if (settings.data.search('sib_form_action') != -1 && xhr.readyState == 4) {
            if (xhr.responseJSON.status == 'success') {
              if (forms2GA.langCode) {
                forms2GA.triggerGA('Formulaire', 'Inscription newsletter', forms2GA.langCode)
              } else {
                forms2GA.triggerGA('Formulaire', 'Inscription newsletter', 'Unknown')
              }
            }
          }
        }
      })
      return 'setAllOtherListeners set on \'sib_form_action\''
    },

    setNinjaListener() {
      if (typeof Marionette !== 'undefined' && typeof nfForms !== 'undefined') {
        const ninjaformSubmitController = Marionette.Object.extend({
          initialize() {
            this.listenTo(Backbone.Radio.channel('forms'), 'submit:response', this.actionSubmit)
          },
          actionSubmit(response) {
            const ga = window.ga || function () {
              console.log('forms2GA => ' + '[Nematis] ga is not defined !')
            }
            if (forms2GA.langCode) {
              if (response.errors == false) {
                forms2GA.triggerGA('Formulaire', response.data.settings.title, forms2GA.langCode)
              } else {
                forms2GA.triggerGA('Formulaire', response.data.settings.title, 'Unknown')
              }
            }
          }
        })
        new ninjaformSubmitController()
        let allNfForms = ''
        for (let i = 0; i < window.nfForms.length; i++) {
          allNfForms = ` | ${nfForms[i].settings.title}${allNfForms}`
        }
        return `Ninja Form detected${allNfForms}`
      }
      return 'Ninja Form not detected'
    },

    setwpcf7Listener() {
      const wpcf7Object = window.wpcf7 || window._wpcf7
      if (typeof wpcf7Object !== 'undefined') {
        document.addEventListener('wpcf7mailsent', (event) => {
          // Gestion d'erreurs humaines. Le formulaire remontera comme :
          // id si erreur | 1801
          // form id si pas de html_name | wpcf7-f1801-p1802-o1
          // form name si tout va bien | Contact
          let currentCfForm = ''
          if (typeof event.detail.apiResponse !== 'undefined' && typeof event.detail.apiResponse.into !== 'undefined') {
            currentCfForm = event.detail.apiResponse.into.replace('#', '')
            const targetedCfForm = document.getElementById(currentCfForm).querySelector('.wpcf7-form').name
            if (typeof targetedCfForm !== 'undefined') {
              currentCfForm = targetedCfForm
            } // Esthétique
          }
          if (forms2GA.langCode) {
            forms2GA.triggerGA('Formulaire', currentCfForm, forms2GA.langCode)
          } else {
            forms2GA.triggerGA('Formulaire', currentCfForm, 'Unknown')
          }
        }, false)
        /*
        var allCfForms = '';
        var allCfFormsObjects = document.getElementsByClassName('wpcf7-form');
        for (var i=0;i<allCfFormsObjects.length;i++) {
          allCfForms = ' | ' + allCfFormsObjects[i].action.replace(/.*wpcf7-f/, '').split('-')[0] + ' > ' + document.getElementsByClassName('wpcf7-form')[i].name + allCfForms;
        }
        return("Contact Form detected :" + allCfForms);
      } else {
        return("Contact Form not detected");*/
      }
    },

    setByClassListener(myclass) {
      myclass = typeof myclass !== 'undefined' ? myclass : 'ga-trigger'
      forms2GA.debug += '[GOOD] SetByClass initialisé\n'
      const t = document.getElementsByClassName(myclass)
      for (let i = 0; i < t.length; i++) {
        t[i].addEventListener('click', function (event) {
          Nematis.forms2GA.classClickCallback(event, this)
        }, false)
      }
      if (t.length > 0) {
        return `setByClassListener set on '${myclass}'`
      }
      return 'setByClassListener unset'
    },

    classClickCallback(event, me) {
      const txt = me.firstChild.innerHTML
      const linkValue = me.href.split('#')[1]
      me.href = me.href.split('#')[0]
      // click, Page Tarifs, Adulte (FR)
      Nematis.forms2GA.triggerGA('click', linkValue, forms2GA.langCode)
    },

    GASetter() {
      if (typeof gaData !== 'undefined') {
        forms2GA.gaKey = Object.keys(gaData)[0]
      } else {
        return window.setTimeout(forms2GA.GASetter, 2000)
      }
    },

    GADataApi(element) {
      let category = $(element).data('ga-event-category')
      let action = $(element).data('ga-event-action')
      let label = $(element).data('ga-event-label')

      if (typeof category === 'undefined' || $.trim(category) === '') {
        return
      }

      if (typeof action === 'undefined' || $.trim(action) === '') {
        return
      }

      category = forms2GA._GADataApiValue(category)
      action = forms2GA._GADataApiValue(action)
      label = forms2GA._GADataApiValue(label)

      forms2GA.triggerGA(category, action, label)
    },

    _GADataApiValue(value, type = 'string') {
      const jsRegex = /js\((.*)\)/i
      if (jsRegex.test(value)) {
        try {
          value = eval(value.match(jsRegex)[1])
        } catch (e) {
          console.error('forms2GA._GADataApiValue: js command not working')
        }
      }

      switch (type) {
        case 'string':
        default:
          return String(value)
        case 'int':
        case 'integer':
          return parseInt(value, 10)
        case 'float':
          return parseFloat(value)
      }
    },

    init() {
      if (typeof window.ga !== 'undefined') {
        forms2GA.gaType = 'universal'
      }

      if (typeof window._gaq !== 'undefined') {
        forms2GA.gaType = 'analytics'
      }

      if (typeof window.gtag !== 'undefined') {
        forms2GA.gaType = 'gtag'
      }

      if (forms2GA.gaType) {
        window.setTimeout(forms2GA.GASetter, 2000)
        forms2GA.getLangCode()
        forms2GA.debug += `${forms2GA.setNinjaListener()}\n` // WP Ninja Forms 3+ listener
        forms2GA.debug += `${forms2GA.setwpcf7Listener()}\n` // WP Contact Form 7 listener
        forms2GA.debug += `${forms2GA.setByClassListener()}\n` // boutons par classe ('ga-trigger' ou paramètre)
        forms2GA.debug += `${forms2GA.setAllOtherListeners()}\n` // Divers, notamment SendInBlue (interception toutes les Ajax)
        forms2GA.run = true
        forms2GA.debug += '[GOOD] Init terminé.\n'

        /*
         * Data api
         *
         * <element
         *  data-ga-event-on="click|load|..." // One or more space-separated event types
         *  data-ga-event-category="{string}" // Need js value ? Use data-ga-event-category="js({js_code})"
         *  data-ga-ga-event-action="{string}" // Need js value ? Use data-ga-event-category="js({js_code})"
         *  data-ga-event-label="{string}" // Need js value ? Use data-ga-event-category="js({js_code})"
         * >
         */
        $('[data-ga-event-on]').each((i, element) => {
          $(element).on($(element).data('ga-event-on'), (event) => {
            forms2GA.GADataApi(element)
          })
        })

        // onload simulation
        $('[data-ga-event-on~="load"]').each((i, element) => {
          forms2GA.GADataApi(element)
        })
      } else {
        forms2GA.debug += '[ERREUR] Pas de GA trouvé. Le module est-il bien installé ?\n'
      }
    }
  }

  /*
  * Ne pas toucher.
  *
  * Ajout au namespace "Nematis" s'il existe,
  * création de celui-ci sinon.
  *
  */
  window.Nematis = window.Nematis || {}
  Nematis.forms2GA = Nematis.forms2GA || forms2GA
  Nematis.forms2GA.init()
  /*
  * Coder après ces lignes ;)
  */
})
