(function ($, w, d, undefined) {
  // Menu toggle behavior
  const elBtnOpen = d.querySelector('.menu-toggle')
  const mainNavWrapper = d.querySelector('.main-nav-wrapper')
  const menuImageWrapper = d.querySelector('.menu-image-wrapper')
  const body = d.querySelector('body')
  if (elBtnOpen) {
    elBtnOpen.addEventListener('click', () => {
      elBtnOpen.classList.toggle('opened')
      const header = d.querySelector('#header')
      if (mainNavWrapper.classList.contains('open')) {
        mainNavWrapper.classList.add('closing')
      }
      header.classList.toggle('open')
      mainNavWrapper.classList.toggle('open')
      body.classList.toggle('modal-open')
    })
  }

  if (menuImageWrapper) {
    menuImageWrapper.addEventListener('transitionend', () => {
      mainNavWrapper.classList.remove('closing')
    })
  }

  d.addEventListener('DOMContentLoaded', () => {
    const menuItemWithChild = d.querySelectorAll('.menu-item-has-children > a')
    menuItemWithChild.forEach((item) => {
      const subMenuItem = item.parentElement.querySelectorAll('ul > .menu-item')
      const subMenu = item.parentElement.querySelector('ul')
      let submenuHeight = 0
      if (subMenuItem.length) {
        subMenuItem.forEach((subMenuItemEl) => {
          submenuHeight += subMenuItemEl.offsetHeight + 8
        })
        subMenu.style.maxHeight = `${submenuHeight}px`
      }
      item.addEventListener('click', function (e) {
        e.preventDefault()

        menuItemWithChild.forEach((item) => {
          if (this != item && item.parentElement.classList.contains('menu-expand')) {
            item.parentElement.classList.remove('menu-expand')
            item.parentElement.classList.add('menu-collapse')
          }
        })

        if (this.parentElement.classList.contains('menu-expand')) {
          this.parentElement.classList.remove('menu-expand')
          this.parentElement.classList.add('menu-collapse')
        } else {
          this.parentElement.classList.remove('menu-collapse')
          this.parentElement.classList.add('menu-expand')
        }
      })
    })
  })
}($, window, document))
