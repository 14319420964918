(function ($, w, d, undefined) {
  d.addEventListener('DOMContentLoaded', () => {
    const iOS = Boolean(navigator.platform) && /iPad|iPhone|iPod/.test(navigator.platform)
    if (iOS) {
      // Hack to set height on flex child element
      const poolArchiveItem = d.querySelectorAll('.product-tease-wrapper:not(.card) .tease-content-wrapper .tease-content')
      if (poolArchiveItem) {
        poolArchiveItem.forEach((item) => {
          item.style.height = '190px'
        })
      }
    }
  })
}($, window, document))


