(function ($, w, d, undefined) {
  d.addEventListener('map.reloaded', () => {
    const mapWrapper = d.querySelector('#dealers-map')
    if (mapWrapper) {
      mapBehavior(mapWrapper)
    }
  })

  d.addEventListener('DOMContentLoaded', () => {
    const mapWrapper = d.querySelector('#dealers-map')
    if (mapWrapper) {
      mapBehavior(mapWrapper)
    }

    setTimeout(() => {
      repositionElement()
    }, 100)

    const mqPortrait = window.matchMedia('(orientation: portrait)')
    mqPortrait.addListener((query) => repositionElement())

    document.addEventListener('resize', () => repositionElement())
  })

  function repositionElement() {
    const header = d.querySelector('#header')
    if (header) {
      // Set Promo Video height
      const promoContent = d.querySelector('.block-promo-content')
      const promoVideoBg = d.querySelector('.block-promo-video-bg')

      if (promoContent) {
        const blockHeight = promoContent.offsetHeight  // 48 = 3rem
        promoVideoBg.style.height = `${blockHeight}px`
      }

      const event = new Event('header.repositioned') // (2)
      d.dispatchEvent(event)
    }
  }

  function repositionMap(mapWrapper, type = 'desktop') {
    const header = d.querySelector('#header')
    const contentWrapper = d.querySelector('.dealer-posts-wrapper')
    const offset = header.offsetHeight
    // Set Map height
    let adminbarHeight = 0
    const adminbar = document.querySelector('#wpadminbar')
    if (adminbar) {
      adminbarHeight = adminbar.offsetHeight
    }

    const selectorEl = d.querySelector('.department-selector-wrapper')
    const topOffset = offset + adminbarHeight
    const bottomOffset = selectorEl.offsetHeight
    if (type == 'mobile') {
      mapWrapper.style.height = ''
      mapWrapper.style.top = `${topOffset}px`
      mapWrapper.style.bottom = `${bottomOffset}px`
      mapWrapper.style.width = '100%'
    } else if (type == 'tablet') {
      mapWrapper.style.height = ''
      mapWrapper.style.width = 'calc(100% - 5rem)'
      mapWrapper.style.top = `${topOffset}px`
      mapWrapper.style.bottom = `${bottomOffset}px`
    } else {
      const mapOffset = offset + adminbarHeight
      const windowHeight = window.innerHeight - mapOffset
      if (contentWrapper.offsetHeight > windowHeight) {
        mapWrapper.style.height = `calc(100vh - ${mapOffset}px)`
      } else {
        mapWrapper.style.height = `${contentWrapper.offsetHeight}px`
      }
      mapWrapper.style.bottom = ''
      mapWrapper.style.top = ''
    }
  }

  function mapBehavior(mapWrapper) {
    const mqTablet = window.matchMedia('(min-width:768px)')
    const mqDesktop = window.matchMedia('(min-width:992px)')
    let type = 'mobile'
    if (mqDesktop.matches) {
      type = 'desktop'
    } else if (mqTablet.matches) {
      type = 'tablet'
    }

    repositionMap(mapWrapper, type)

    mqTablet.addListener((query) => {
      if (query.matches) {
        type = 'tablet'
      } else {
        type = 'mobile'
      }
      repositionMap(mapWrapper, type)
    })

    mqDesktop.addListener((desktopQuery) => {
      setTimeout(() => {
        if (desktopQuery.matches) {
          type = 'desktop'
        } else {
          type = 'tablet'
        }
        repositionMap(mapWrapper, type)
      }, 100)
    })
  }
}($, window, document))
