(function (w, d, undefined) {
  const comments = d.querySelectorAll('hr.more-auto-collapse')

  comments.forEach((comment) => {
    const wrapper = $(comment).parents('[data-read-more]')
    const readMore = wrapper.data('readMore') || 'Read more'
    const resolution = wrapper.data('resolution') || 'mobile'
    // const readMoreLink = `<a class="readMore" href="javascript:void(0)"><span>${readMore}</span></a>`
    if (w.outerWidth > 767 && resolution != 'all') {
      return
    }
    let nextAll = $(comment).nextAll()
    if (nextAll.length < 1) {
      nextAll = $(comment).parent().nextAll()
    }

    const readMoreLink = document.createElement('a')
    const readMoreLabel = document.createTextNode(readMore)
    readMoreLink.appendChild(readMoreLabel)
    readMoreLink.href = 'javascript:void(0)'
    readMoreLink.title = readMore
    comment.parentNode.insertBefore(readMoreLink, comment.nextSibling)
    comment.remove()

    readMoreLink.addEventListener('click', () => {
      $(readMoreLink).hide()
      nextAll.show()
    })
    nextAll.hide()
  })
}(window, document))
